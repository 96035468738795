<template lang="pug">
  .shows.container.px-3
    .columns.pb-4
      .column.has-text-centered
        h2.title.secondary-text-color.is-3 Shows
      .column.is-narrow
        b-button(v-if="loggedIn" type="is-info" @click="showNewShowModal=true") Add Show
    .shows-grid.px-5
      .columns(v-for="show in shows" v-if="determineDate(show.date)")
        .column.is-one-fourth
          strong {{ show.formattedDate }}
          p.custom-margin {{ show.venue }}
        .column.has-text-centered.is-flex.is-justify-content-center
          p.is-align-self-flex-end {{ show.location }}
        .column.is-one-fourth.has-text-right.ticket-button.is-flex.is-justify-content-end
          b-button.is-align-self-center(
            v-if="show.ticketLink"
            :href="show.ticketLink"
            tag="a"
            target="_blank"
            type="is-primary"
            ) Tickets
        .column.is-narrow.is-flex.is-justify-content-end(v-if="loggedIn")
          b-button.mr-1.is-align-self-center(
            icon-right="pencil-alt"
            size="is-small"
            type="is-warning"
            @click="editShow(show.id)"
            )
          b-button.is-align-self-center(
            icon-right="trash"
            size="is-small"
            type="is-danger"
            @click="deleteShow(show.id)"
            )
    b-modal(v-if="loggedIn" v-model="showNewShowModal" @after-leave="resetShow")
      .box
        h2.title {{ isEdit ? 'Update' : 'Create' }} Show
        b-field(label="Date")
          b-datepicker(v-model="newShow.date" :date-formatter="formatDate" placeholder="10/16/2021" mobile-native=false)
        b-field(label="Venue")
          b-input(v-model="newShow.venue" placeholder="Cervantes")
        b-field(label="City")
          b-input(v-model="newShow.city" placeholder="Denver")
        b-field(label="State")
          b-input(v-model="newShow.state" placeholder="Colorado")
        b-field(label="Price")
          b-input(v-model="newShow.price" placeholder="$10-$15")
        b-field(label="Ticket Link")
          b-input(v-model="newShow.ticketLink" placeholder="Type 'venmo' if it's a will call show")
        .is-flex.is-justify-content-flex-end
          b-button(type="is-ghost" @click="showNewShowModal = false") Cancel
          b-button(type="is-info" :disabled="!canSaveShow" @click="saveShow") {{ isEdit ? 'Update' : 'Create' }}
</template>

<script>
import { mapGetters } from 'vuex';
import { showsCollection } from '../firebase';

export default {
  metaInfo() {
    return {
      title: 'Upcoming Shows - Sqwerv',
      meta: [
        {
          hid: 'sqwervShowsPage',
          name: 'description',
          content: 'Sqwerv shows page. See what shows Sqwerv has coming up so you can come checkout the action.',
        },
      ],
    }
  },
  name: 'Shows',
  data() {
    return {
      checkedRows: [],
      isEdit: false,
      newShow: {},
      showNewShowModal: false,
    };
  },
  computed: {
    ...mapGetters(['shows', 'loggedIn']),
    canSaveShow() {
      return !!this.newShow.date && !!this.newShow.venue && !!this.newShow.city && !!this.newShow.state;
    },
  },
  methods: {
    determineDate(showDate) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 1);
      return showDate >= currentDate;
    },
    editShow(showId) {
      this.isEdit = true;
      this.showNewShowModal = true;
      this.newShow = this.shows.find(show => showId === show.id);
    },
    formatDate(date) {
      const formattedDate = Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'numeric', day: 'numeric'}).format(date);
      this.newShow.formattedDate = formattedDate;
      return  formattedDate;
    },
    resetShow() {
      this.isEdit = false;
      this.checkedRows = [];
      this.newShow = {};
    },
    async saveShow() {
      if (this.newShow.ticketLink === 'venmo') {
        this.newShow.ticketLink = 'https://account.venmo.com/u/sqwervmusic';
      } else if (this.newShow.ticketLink === '') {
        this.newShow.ticketLink = 'https://www.facebook.com/Sqwerv';
      }
      try {
        await this.newShow.id ? showsCollection.doc(this.newShow.id).update(this.newShow) : showsCollection.add(this.newShow);
        this.$buefy.toast.open({ message: 'Successfully Saved Show', type: 'is-success' });
        this.showNewShowModal = false;
      } catch {
        this.showNewShowModal = false;
        this.$buefy.toast.open({ message: 'Failed to Save Show', type: 'is-danger' });
      }
    },
    async deleteShow(showId) {
      try {
        await showsCollection.doc(showId).delete();
        this.$buefy.toast.open({ message: 'Successfully Deleted Show', type: 'is-success' });
        this.showNewShowModal = false;
      } catch {
        this.showNewShowModal = false;
        this.$buefy.toast.open({ message: 'Failed to Delete Show', type: 'is-danger' });
      }
    },
  },
};
</script>

<style lang="scss">
  .shows {
    padding-top: 3% !important;
    font-size: 1.1em;
    .secondary-text-color {
      color: #68b8c3;
    }
    .custom-margin {
      margin-top: 6px;
    }
    @media screen and (max-width: 768px) {
      .shows-grid {
        .columns {
          margin-bottom: 3em;
          .column, .ticket-button {
            text-align: center !important;
            padding: 0;
            justify-content: center !important;
          }
          p {
            margin-top: 6px;
            margin-bottom: 0;
          }
          .ticket-button {
            margin-top: 13px;
          }
        }
      }
    }
  }
</style>
