<template lang="pug">
  .social-navbar.is-flex.pt-1.is-justify-content-center(:class="{ 'home-styling': isHome }")
    a.m-1(href="https://open.spotify.com/artist/3xrbKno8shx3H22Xz8omC8?si=-ErxmcGVSQiUQrkaQ_ttbg" target="_blank" rel="noopener")
      b-icon(icon="spotify" pack="fab" :custom-size="size" type="is-white")
    a.m-1(href="https://music.apple.com/us/artist/grim-fossa/1210521623" target="_blank" rel="noopener")
      b-icon(icon="apple" pack="fab" :custom-size="size" type="is-white")
    a.m-1(href="https://www.facebook.com/Sqwerv" target="_blank" rel="noopener")
      b-icon(icon="facebook-square" pack="fab" :custom-size="size" type="is-white")
    a.m-1(href="https://www.youtube.com/channel/UCtbI8FWG4a1As9wx1E4pYyQ" target="_blank" rel="noopener")
      b-icon(icon="youtube" pack="fab" :custom-size="size" type="is-white")
    a.m-1(href="https://www.instagram.com/sqwerv/" target="_blank")
      b-icon(icon="instagram" pack="fab" :custom-size="size" type="is-white" rel="noopener")
    a.m-1(href="https://sqwerv.bandcamp.com/" target="_blank")
      b-icon(icon="bandcamp" pack="fab" :custom-size="size" type="is-white" rel="noopener")
</template>

<script>
export default {
  name: 'SocialNavbar',
  props: {
    size: {
      type: String,
      default: 'fa-2x',
    },
    isHome: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  .home-styling {
    flex-direction: column;
    bottom: 2%;
    position: absolute;
    right: 1%;
  }
</style>
