<template lang="pug">
  .listen.py-5.px-3
    .container.is-max-desktop
      h2.title.is-1.has-text-white Listen
      .is-flex.is-flex-direction-column.background-spotify
        iframe.album-slot(src="https://open.spotify.com/embed/artist/3xrbKno8shx3H22Xz8omC8" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media")
</template>

<script>
export default {
  name: 'Listen',
};
</script>

<style lang="scss">
  .listen {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/sqwerv-website-89fae.appspot.com/o/Sheer_G_Sqwerv_06.04.21_003%20(3).jpg?alt=media&token=12b08a5f-d915-42d6-a5cb-9a116a732ea6");
    background-repeat: no-repeat;
    background-size: cover;

    .background-spotify {
      background: #b9b9b982;
      border-radius: 10px;
    }
    .album-slot {
      border-radius: 10px;
      opacity: .75;
    }
    iframe {
      width: 100%;
      h1 {
        font-size: 3em;
      }
    }
  }
</style>
