<template lang="pug">
  #app
    .redirect-box.box(v-if="showRedirect")
      h1 You are being redirected...
    NavbarAdmin(v-if="userLoggedIn")
    Navbar(v-if="$route.name !== 'login'")
    router-view.view-height.pb-5
    sqwerv-footer(v-if="$route.name !== 'login'")
</template>

<script>
import Navbar from './components/Navbar.vue';
import NavbarAdmin from './components/NavbarAdmin.vue';
import SqwervFooter from './components/SqwervFooter.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    NavbarAdmin,
    SqwervFooter,
  },
  data() {
    return {
      redirects: 0,
      showRedirect: false,
    }
  },
  computed: {
    userLoggedIn() {
      return this.$store.state.userLoggedIn;
    },
  },
  // watch: {
  //   '$route': {
  //     immediate: true,
  //     handler(to) {
  //       if (to.path === '/merch' && this.redirects === 0) {
  //         window.location.href = 'https://sqwerv.myshopify.com/';
  //       } else if (to.path === '/merch') {
  //         window.open('https://sqwerv.myshopify.com/', '_blank_');
  //         this.$router.push({ name: 'home' });
  //       }
  //       this.redirects += 1;
  //     }
  //   }
  // },
  mounted() {
    if (this.$route.query.redirect) {
      this.showRedirect = true;
    }
  },
};
</script>

<style lang="scss">
  @import '~@/assets/scss/variables.scss';
  .view-height {
    min-height: 86vh;
  }
  .required label:first-child:after {
    content: '*';
    color: $red;
    font-size: .8rem;
  }
  .redirect-box {
    position: absolute;
    z-index: 1000;
    right: 0;
    left: 0;
    text-align: center;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 34px;
  }
</style>
