import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyD1jDqeJnwKZH8t7QMc99-cmAB0MkN02jo',
  authDomain: 'sqwerv-website-89fae.firebaseapp.com',
  projectId: 'sqwerv-website-89fae',
  storageBucket: 'sqwerv-website-89fae.appspot.com',
  messagingSenderId: '18954460744',
  appId: '1:18954460744:web:aa23a6f611f51c31a9827d',
  measurementId: 'G-E39YCNNFC9',
};
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();

const merchCollection = db.collection('merch')
const newsCollection = db.collection('news')
const pressCollection = db.collection('pressLinks')
const quotesCollection = db.collection('quotes')
const showsCollection = db.collection('shows')
const videosCollection = db.collection('videos')

export {
  db,
  auth,
  merchCollection,
  newsCollection,
  pressCollection,
  quotesCollection,
  showsCollection,
  videosCollection,
};
