<template lang="pug">
  .sqwerv-footer.columns
    .column.is-flex.is-flex-direction-column.is-justify-content-space-around
      .has-text-centered
        h3.title.is-size-5.has-text-white.mb-2 Booking Inquiries
        p justin@looseleaftalentagency.com
        p gabe@looseleaftalentagency.com
      .has-text-centered
        social-navbar(size="fa-lg")
        p ⓒ Sqwerv Records LLC  2023
    .column
      mail-chimp-input
</template>

<script>
import MailChimpInput from '@/components/MailChimpInput.vue';
import SocialNavbar from '@/components/SocialNavbar.vue';

export default {
  name: 'SqwervFooter',
  components: {
    MailChimpInput,
    SocialNavbar,
  },
};
</script>

<style lang="scss" scoped>
  .sqwerv-footer {
    padding: 1em 1.5em 3em;
    background: #3a3838;
    color: white;
  }
</style>
