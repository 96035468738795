<template lang="pug">
  .about.container.p-3
    b-image.object-position-custom(src="https://firebasestorage.googleapis.com/v0/b/sqwerv-website-89fae.appspot.com/o/EK6A7954.jpg?alt=media&token=96eb304d-4afe-4457-a6d7-980e03895081&_gl=1*fra5p8*_ga*NDIxNDM2OTcyLjE2OTQ3OTMyNDE.*_ga_CW55HF8NVT*MTY5ODY5Nzk3MS41LjEuMTY5ODY5ODAxMS4yMC4wLjA." ratio="7by3" alt="Sqwerv band photo")
    h2.title.secondary-text-color.mt-4 Our Journey
    .columns.mt-3
      .column.has-line-height
        p.has-text-grey Sqwerv's roots run deep in the Denver music scene. Those roots began to take hold as the band started hosting local open jams and playing gigs at small bars and venues in 2017. Since then, Sqwerv has become a staple in Denver's music scene with bands like Magic Beans, Squeaky Feet, Eminence Ensemble and Rado.  
        br
        p.has-text-grey This four-piece Indie-Psych Rock band’s love for classic rock, psychedelic music and improvisation creates a sound that is refreshingly original and exciting. Sqwerv realizes the importance of good songwriting and is always pushing themselves in the improvisation. The band spends a great deal of time writing lyrics, composing, and developing song structure that has grown into a catalogue that ranges from twelve minute prog songs inspired by Homer's Odyssey to two minute psych songs about love. They are fiercely driven to get to those transcendent, improvised spaces that all jam-music lovers crave. Sqwerv continues to deliver to their audiences through groove, counterpoint, polyrhythms and risk taking.
        br
        p.has-text-grey In the last two years the band has released two studio albums, three live albums and completed two national tours with no plans on stopping. They have had the opportunity to open for acts like Lespecial, Spafford, Eggy and Big Something. They hold themselves to a rigorous tour schedule playing unique shows for their fans on a regular basis. Sqwerv leans into the dark and the beautiful while thriving off of the sounds they create and connections they make.
      .column.is-4
        b-image.text-image(src="https://firebasestorage.googleapis.com/v0/b/sqwerv-website-89fae.appspot.com/o/Sheer_G_Sqwerv_2023.10.21_033.jpg?alt=media&token=348be855-bd7b-4bad-9940-7d090c8b1759&_gl=1*shaa39*_ga*NDIxNDM2OTcyLjE2OTQ3OTMyNDE.*_ga_CW55HF8NVT*MTY5ODY5Nzk3MS41LjEuMTY5ODY5ODIzNy4xOC4wLjA." ratio="4by4" alt="Sqwerv band photo")
    .columns.mt-6
      .column
        b-image(src="https://firebasestorage.googleapis.com/v0/b/sqwerv-website-89fae.appspot.com/o/EK6A8214.jpg?alt=media&token=6f478e0d-62e5-439a-83a3-4ece22eb9ff4&_gl=1*1q17sy3*_ga*NDIxNDM2OTcyLjE2OTQ3OTMyNDE.*_ga_CW55HF8NVT*MTY5ODY5Nzk3MS41LjEuMTY5ODY5ODQ3NS41NS4wLjA." ratio="4by3" alt="Sqwerv band photo")
      .column
        b-image.object-position-top(src="https://firebasestorage.googleapis.com/v0/b/sqwerv-website-89fae.appspot.com/o/Sheer_G_Sqwerv_2023.10.21_018.jpg?alt=media&token=4b680287-a433-4cf4-a3db-8b7d49283e77&_gl=1*10dawks*_ga*NDIxNDM2OTcyLjE2OTQ3OTMyNDE.*_ga_CW55HF8NVT*MTY5ODY5Nzk3MS41LjEuMTY5ODY5ODYyNC41MS4wLjA." ratio="4by3" alt="Sqwerv band photo")
      .column
        b-image(src="https://firebasestorage.googleapis.com/v0/b/sqwerv-website-89fae.appspot.com/o/4.jpg?alt=media&token=6adb6b10-d6ae-43cb-93ba-2f30de783fc3" ratio="4by3" alt="Sqwerv band photo")
    .columns
      .column
        b-image.object-position-top(src="https://firebasestorage.googleapis.com/v0/b/sqwerv-website-89fae.appspot.com/o/15.jpg?alt=media&token=aa5e5ad7-9c81-4965-87dc-8f0f4618b591" ratio="6by3" alt="Sqwerv band photo")
      .column
        b-image(src="https://firebasestorage.googleapis.com/v0/b/sqwerv-website-89fae.appspot.com/o/8.jpg?alt=media&token=d1c82712-8196-4002-8efd-9df6108b0088" ratio="6by3" alt="Sqwerv band photo")
    .columns
      .column
        b-image(src="https://firebasestorage.googleapis.com/v0/b/sqwerv-website-89fae.appspot.com/o/12.jpg?alt=media&token=a87ea4ed-3edc-4bf5-a151-a01842f48bac" ratio="4by3" alt="Sqwerv band photo")
      .column
        b-image(src="https://firebasestorage.googleapis.com/v0/b/sqwerv-website-89fae.appspot.com/o/14.jpg?alt=media&token=6ed99898-e577-4f05-80fa-411ff274fdff" ratio="4by3" alt="Sqwerv band photo")
      .column
        b-image(src="https://firebasestorage.googleapis.com/v0/b/sqwerv-website-89fae.appspot.com/o/16.jpg?alt=media&token=66314dc4-e1ba-4a70-8870-a6ec8264151f" ratio="4by3" alt="Sqwerv band photo")
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'About Page - Sqwerv',
      meta: [
        {
          hid: 'sqwervAboutPage',
          name: 'description',
          content: 'Sqwerv biography, story, origins, gallery photos. Explains the story of how Guy Frydenlund, Caden Kramer, Jack Marty, and Zach Bulgarelli met',
        },
      ],
    }
  },
  name: 'About',
};
</script>

<style lang="scss">
  .about {
    padding-top: 3% !important;
    .secondary-text-color {
      color: #68b8c3;
    }
    .has-line-height {
      line-height: 2em;
      font-size: 1.1em;
    }
    .text-image {
      height: 100%;
      img {
        object-position: 35%;
      }
    }
    .object-position-top {
      img {
        object-position: top;
      }
    }
    .object-position-custom {
      img {
        object-position: 0 8%;
      }
    }
  }
</style>
