import Home from '@/views/Home.vue';
import About from '@/views/About.vue';
import ContactUs from '@/views/ContactUs.vue';
import Listen from '@/views/Listen.vue';
import Login from '@/views/Login.vue';
import Merch from '@/views/Merch.vue';
import News from '@/views/News.vue';
import Press from '@/views/Press.vue';
import Shows from '@/views/Shows.vue';
import Videos from '@/views/Videos.vue';

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: ContactUs,
  },
  {
    path: '/listen',
    name: 'listen',
    component: Listen,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/merch',
    name: 'merch',
    component: Merch,
  },
  {
    path: '/news',
    name: 'news',
    component: News,
  },
  {
    path: '/shows',
    name: 'shows',
    component: Shows,
  },
  {
    path: '/press',
    name: 'press',
    component: Press,
  },
  {
    path: '/videos',
    name: 'videos',
    component: Videos,
  },
];
