import Vue from 'vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import Buefy from 'buefy';
import router from './router';
import store from './store';
import { auth } from './firebase';

Vue.use(Buefy, {
  defaultIconPack: 'fa',
});

Vue.use(VueMeta);

Vue.config.productionTip = false;

let app;
auth.onAuthStateChanged((user) => {
  if (!app) {
    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app');
  }

  if (user.email === 'sqwervmusic@gmail.com') {
    store.commit('LOGIN_USER')
  }
})
