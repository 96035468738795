<template lang="pug">
  .about.container.p-3
    b-image.object-position-custom.mb-3(src="https://firebasestorage.googleapis.com/v0/b/sqwerv-website-89fae.appspot.com/o/EK6A8214WEB.jpg?alt=media&token=32126c27-8300-48fa-a6ac-14c941d7d694&_gl=1*6ogmpd*_ga*NDIxNDM2OTcyLjE2OTQ3OTMyNDE.*_ga_CW55HF8NVT*MTY5OTA0MjE2Mi4xMy4xLjE2OTkwNDQwNzUuMTEuMC4w" ratio="7by2" alt="Sqwerv band photo")
    .columns.is-vcentered
      .column
        h2.title.secondary-text-color.is-3 Merch
      .column.is-narrow
        b-button(v-if="loggedIn" type="is-info" @click="showNewItemModal=true") Add Item
    p.is-italic.pb-2 To purchase merchandise, reach out to us through the 'Contact Us' page with your shipping address and merch order and we'll respond with next steps.
    .columns.is-multiline
      .column.is-4.mt-2.px-5(v-for="(m, index) in merch" v-if="m.visible" :key="index")
        b-carousel(:arrow="m.images.length > 1" :autoplay="false" :indicator="m.images.length > 1" :indicator-custom="m.images.length > 5" :pause-info="false")
          b-carousel-item(v-for="(img, i) in m.images" :key="i")
            div.is-clickable(@click="showViewImageModal(m.images)")
              b-image.object-contain(:src="img" ratio="1by1" alt="Sqwerv merch photo")
        p.has-text-grey {{ m.name }}
        p.has-text-grey {{ m.price }}
        .is-flex
          b-button.mr-1(
            v-if="loggedIn"
            icon-right="pencil-alt"
            size="is-small"
            type="is-warning"
            @click="editMerch(m.id)"
            )
    b-modal(v-if="loggedIn" v-model="showNewItemModal" @after-leave="resetItem")
      .box
        h2.title {{ isEdit ? 'Update' : 'Create' }} Item
        b-field(label="Name")
          b-input(v-model="newItem.name" placeholder="Baseball Hats")
        b-field(label="Price")
          b-input(v-model="newItem.price" placeholder="$10")
        b-field(label="Images")
          .div.is-flex.is-flex-direction-column
            b-input.mb-1(v-model="newItem.images[0]" placeholder="Firebase Image 1")
            b-input(v-model="newItem.images[1]" placeholder="Firebase Image 2")
        b-field(label="Visible")
          b-checkbox(v-model="newItem.visible")
        .is-flex.is-justify-content-flex-end
          b-button(type="is-ghost" @click="showNewItemModal=false") Cancel
          b-button(type="is-info" :disabled="!canSaveItem" @click="saveItem") {{ isEdit ? 'Update' : 'Create' }}
    b-modal(v-model="viewImageModal" @after-leave="resetImage")
      .box.is-flex.is-justify-content-center
        b-carousel.view-image-modal(:arrow="imagesToView.length > 1" :autoplay="false" :indicator="imagesToView.length > 1" :indicator-custom="imagesToView.length > 5" :pause-info="false")
          b-carousel-item(v-for="(img, i) in imagesToView" :key="`modal-${i}`")
            b-image.object-contain.view-image-modal(:src="img" ratio="1by1" alt="Sqwerv merch photo")
</template>

<script>
import { mapGetters } from 'vuex';

import { merchCollection } from '../firebase';

export default {
  metaInfo() {
    return {
      title: 'Merch Page - Sqwerv',
      meta: [
        {
          hid: 'sqwervMerchPage',
          name: 'description',
          content: 'Sqwerv merch where they sell tshirts, hoodies, hats, pins, lighters, and more',
        },
      ],
    }
  },
  name: 'Merch',
  data() {
    return {
      imagesToView: [],
      isEdit: false,
      newItem: {
        images: [],
        name: '',
        price: '',
        visible: true,
      },
      showNewItemModal: false,
      viewImageModal: false,
    };
  },
  computed: {
    ...mapGetters(['merch', 'loggedIn']),
    canSaveItem() {
      return !!this.newItem.price && !!this.newItem.name
    },
  },
  methods: {
    editMerch(id) {
      this.isEdit = true;
      this.showNewItemModal = true;
      this.newItem = this.merch.find(item => id === item.id);
    },
    resetItem() {
      this.newItem = {
        images: [],
        name: '',
        price: '',
        visible: true,
      };
    },
    resetImage() {
      this.images = [];
    },
    async saveItem() {
      if (!this.newItem.id) {
        this.newItem.dateCreated = new Date();
      }
      try {
        await this.newItem.id ? merchCollection.doc(this.newItem.id).update(this.newItem) : merchCollection.add(this.newItem);
        this.$buefy.toast.open({ message: 'Successfully Saved Item', type: 'is-success' });
        this.showNewItemModal = false;
      } catch {
        this.showNewItemModal = false;
        this.$buefy.toast.open({ message: 'Failed to Save Item', type: 'is-danger' });
      }
    },
    showViewImageModal(images) {
      this.imagesToView = images;
      this.viewImageModal = true;
    },
  },
};
</script>

<style lang="scss">
  .about {
    .view-image-modal {
      width: 40vw;
    }
    .object-contain {
      img {
        object-fit: contain;
      }
    }
  }
</style>
