<template lang="pug">
  .home
    b-image.home-image(src="https://firebasestorage.googleapis.com/v0/b/sqwerv-website-89fae.appspot.com/o/coverphotoweb.jpg?alt=media&token=1c4c5994-cd59-46bb-b2f0-b7e6278ab49f&_gl=1*1jmm8z4*_ga*NDIxNDM2OTcyLjE2OTQ3OTMyNDE.*_ga_CW55HF8NVT*MTY5NjMwNTg0Ni40LjEuMTY5NjMwNjg2OS4zNy4wLjA." alt="Sqwerv band photo")
    social-navbar.social-z-index(is-home)
    news
    listen
    shows.container.is-max-desktop
  </template>

<script>
import Listen from '@/views/Listen.vue';
import News from '@/views/News.vue';
import SocialNavbar from '@/components/SocialNavbar.vue';
import Shows from '@/views/Shows.vue';

export default {
  metaInfo() {
    return {
      title: 'Home Page - Sqwerv',
      meta: [
        {
          hid: 'sqwervHomePage',
          name: 'description',
          content: 'Sqwerv is a 4 piece Indie-Psych-Rock band that took their passion for classic rock and psychedelic music, fused it with a dose of funk, and created a sound that is refreshingly original. When Sqwerv takes the stage their songwriting shines as audiences are taken on a ride through surreal stories, ethereal swirling guitar solos and bellowing bumblebee baselines.',
        },
      ],
    }
  },
  name: 'Home',
  components: {
    SocialNavbar,
    Listen,
    News,
    Shows,
  },
  methods: {
    getDate() {
      let today = new Date();
      const utc = today.getTime() + (today.getTimezoneOffset() * 60000);
      const nd = new Date(utc + (3600000*-5));
      return nd >= new Date('5/15/2024');
    },
  },
};
</script>

<style lang="scss">
  .home {
    .social-z-index {
      z-index: 100;
    }
    .home-image {
      img {
        object-position: top;
        height: 100vh;
      }
    }
    .promo-pic {
      position: absolute;
      z-index: 1;
      width: 30%;
      left: 50%;
      top: 50%;
      border: white;
      border-width: 5px;
      border-style: solid;
      transform: translate(-50%, -50%);
      animation: fadeIn 8s;
    }
    @media screen and (max-width: 1100px) {
      .home-image {
        img {
          object-position: 40% 38%;
        }
      }
    }
    @media screen and (max-width: 620px) {
      .home-image {
        img {
          object-position: 50% 38%;
        }
      }
      .promo-pic {
        width: 80%;
      }
    }
  }
</style>
