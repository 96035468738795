import Vue from 'vue';
import VueMeta from 'vue-meta';
import Router from 'vue-router';
import routes from '@/router/routes';

Vue.use(Router);
Vue.use(VueMeta);

const router = new Router({
  mode: 'history',
  routes,
});

export default router;
