<template lang="pug">
  .videos.container.px-3
    .columns.is-vcentered
      .column
        h2.title.secondary-text-color.is-3 Videos
      .column.is-narrow.has-text-centered
        b-button.mr-2(v-if="loggedIn" type="is-info" @click="showNewVideoModal=true") Add Video
        b-button(
          tag="a"
          href="https://www.youtube.com/channel/UCtbI8FWG4a1As9wx1E4pYyQ"
          type="is-danger"
          icon-right="share"
          target="_blank"
          outlined
          ) Follow us on Youtube
    .my-3(v-if="videoToPlay.url")
      h2.title.is-5.has-text-weight-light {{ videoToPlay.title }}
      figure.image.is-16by9
        iframe.has-ratio(
          :src="videoToPlay.url"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          autoplay="1"
          )
    .columns.is-multiline
      .column.is-4.mt-2(v-for="(video, index) in formattedVideos" v-if="video.visible" @click="videoClicked(video)")
        .is-flex.is-justify-content-space-between(v-if="loggedIn")
          h2.title.is-5.video-title-height.has-text-weight-light {{ video.title }}
          b-button.mr-1.is-align-self-center(
            v-if="loggedIn"
            icon-right="pencil-alt"
            size="is-small"
            type="is-warning"
            @click="editVideo(video.id)"
            )
        h2.title.is-5.video-title-height.has-text-weight-light(v-else) {{ video.title }}
        .thumbnail
          b-image.change-opacity(:src="video.thumbnail" ratio="5by3" :class="{'has-opacity': video.isSelected}"  alt="Sqwerv band photo")
          p.playing-text.has-text-centered.has-text-weight-bold.is-size-4.has-text-white(v-if="video.isSelected") Now Playing
    b-modal(v-if="loggedIn" v-model="showNewVideoModal" @after-leave="resetVideo")
      .box
        h2.title {{ isEdit ? 'Update' : 'Create' }} Video
        b-field(label="Title")
          b-input(v-model="newVideo.title" placeholder="Guy Likes Wieners")
        b-field(label="Url")
          b-input(v-model="newVideo.url" placeholder="https://hottamales.com")
        b-field(label="Thumbnail")
          b-input(v-model="newVideo.thumbnail" placeholder="https://hottamales.com")
        b-field(label="Visible")
          b-checkbox(v-model="newVideo.visible")
        .is-flex.is-justify-content-flex-end
          b-button(type="is-ghost" @click="showNewVideoModal = false") Cancel
          b-button(type="is-info" :disabled="!canSaveVideo" @click="saveVideo") {{ isEdit ? 'Update' : 'Create' }}
</template>

<script>
import { mapGetters } from 'vuex';
import { videosCollection } from '../firebase';

export default {
  metaInfo() {
    return {
      title: 'Videos Page - Sqwerv',
      meta: [
        {
          hid: 'sqwervVideosPage',
          name: 'description',
          content: 'Sqwerv videos page. Watch Sqwev music videos and features. Directly linked to the Sqwev Youtube.',
        },
      ],
    }
  },
  name: 'Videos',
  data() {
    return {
      isEdit: false,
      showNewVideoModal: false,
      videoToPlay: {
        url: '',
        title: '',
      },
      newVideo: {
        thumbnail: 'https://i3.ytimg.com/vi/VIDEO_ID_HERE/maxresdefault.jpg',
        visible: true
      },
    };
  },
  computed: {
    ...mapGetters(['videos', 'loggedIn']),
    canSaveVideo() {
      return !!this.newVideo.title && !!this.newVideo.thumbnail && !!this.newVideo.url;
    },
    formattedVideos() {
      return this.videos.map(video => ({ ...video, isSelected: this.videoToPlay.id === video.id }));
    },
  },
  methods: {
    editVideo(videoId) {
      this.isEdit = true;
      this.showNewVideoModal = true;
      this.newVideo = this.videos.find(video => videoId === video.id);
    },
    resetVideo() {
      this.isEdit = false;
      this.newVideo = {
        thumbnail: 'https://i3.ytimg.com/vi/VIDEO_ID_HERE/maxresdefault.jpg',
        visible: true,
      };
    },
    async saveVideo() {
      try {
        await this.newVideo.id
          ? videosCollection.doc(this.newVideo.id).update(this.newVideo)
          : videosCollection.add({ ...this.newVideo, dateCreated: new Date() });
        this.$buefy.toast.open({ message: 'Successfully Saved Video', type: 'is-success' });
        this.showNewVideoModal = false;
      } catch {
        this.showNewVideoModal = false;
        this.$buefy.toast.open({ message: 'Failed to Save Video', type: 'is-danger' });
      }
    },
    videoClicked(selectedVideo) {
      this.videoToPlay = selectedVideo;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
  },
};
</script>

<style lang="scss">
  .videos {
    padding-top: 3% !important;
    .secondary-text-color {
      color: #68b8c3;
    }
    .thumbnail {
      cursor: pointer;
      width: 100%;
      position: relative;
    }
    .change-opacity {
      &:hover {
        opacity: .7;
      }
    }
    .has-opacity {
      opacity: .5;
    }
    .playing-text {
      opacity: .7;
      position: absolute;
      top: 40%;
      width: 100%;
      background: black;
    }
    .video-title-height {
      height: 25px;
    }
  }
</style>
