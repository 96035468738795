<template lang="pug">
  .login
    .login-form.box
      h1.title Login
      p.has-text-danger(v-if="loginFailed") User email and/or password incorrect
      b-field(label="Email")
        b-input(v-model="form.email")
      b-field(label="Password")
        b-input(v-model="form.password" type="password" password-reveal)
      vue-recaptcha(v-model="imNotARobot" sitekey="6LfK3RAcAAAAALac-QXlkE-TXtATBIRgAeZYanaO" @verify="updateRecaptcha" @expired="resetRecaptcha")
      .is-flex.is-justify-content-flex-end
        b-button.mr-1(type="is-light" tag="a" :href="baseURL") Back to Homepage
        b-button(type="is-info" :disabled="!canLogin" @click="loginUser") Login
</template>

<script>
import { mapGetters } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'Login',
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      form: {},
      imNotARobot: '',
    };
  },
  computed: {
    ...mapGetters(['loginFailed']),
    baseURL() {
      return window.location.origin;
    },
    canLogin() {
      return !!this.form.email && !!this.form.password && !!this.imNotARobot;
    },
  },
  methods: {
    loginUser() {
      this.$store.dispatch('login', this.form);
    },
    resetRecaptcha() {
      this.imNotARobot = '';
    },
    updateRecaptcha(value) {
      this.imNotARobot = value;
    },
  },
};
</script>

<style lang="scss">
  .login {
    height: 100vh;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/sqwerv-website-89fae.appspot.com/o/Sheer_G_Sqwerv_06.04.21_003%20(3).jpg?alt=media&token=12b08a5f-d915-42d6-a5cb-9a116a732ea6");
    background-position-x: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-form {
      width: 50%;
    }
  }
</style>
