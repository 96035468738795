<template lang="pug">
.press-links.py-4.px-3
  .container.is-max-desktop
    .columns.pb-4
      .column.has-text-centered
        h2.title.secondary-text-color.is-3 Press
      .column.is-narrow
        b-button.mr-2(v-if="loggedIn" type="is-info" @click="showNewQuoteModal = true") Add Quote
        b-button(v-if="loggedIn" type="is-info" @click="showNewPressLinkModal = true") Add Press Link
    .columns.mb-4.is-flex.is-align-items-center.quotes
      .column.has-text-centered(v-for="quote in quotes" v-if="quote.visible" :key="quote.id")
        b-button.mr-1.is-align-self-center(
          v-if="loggedIn"
          icon-right="pencil-alt"
          size="is-small"
          type="is-warning"
          @click="editQuote(quote.id)"
          )
        h3.title.is-size-5.secondary-text-color.m-0 "{{ quote.quote }}"
        p.is-italic - {{ quote.publisher }}
    .articles(v-for="link in pressLinks" v-if="link.visible" :key="link.title")
      .article
        .columns.m-0
          .column.m-0
            .is-flex
              h4.title.is-4.has-text-grey.mr-3.mb-1.is-italic {{ link.title }}
              b-button.mr-1.is-align-self-center(
                v-if="loggedIn"
                icon-right="pencil-alt"
                size="is-small"
                type="is-warning"
                @click="editPressLink(link.id)"
                )
            a.is-size-5.secondary-text-color(:href="link.link" target="_blank") {{ link.link }}
            p.mt-1 {{ formatDate(link.date) }}
        hr
  b-modal(v-if="loggedIn" v-model="showNewPressLinkModal" @after-leave="resetPressLink")
    .box
      h2.title {{ isEdit ? 'Update' : 'Create' }} Press Link
      b-field(label="Title")
        b-input(v-model="newPressLink.title" placeholder="Guy Likes Wieners")
      b-field(label="Link")
        b-input(v-model="newPressLink.link")
      b-field(label="Date")
        b-datepicker(v-model="newPressLink.date" placeholder="10/16/2021" mobile-native=false)
      b-field(label="Visible")
        b-checkbox(v-model="newPressLink.visible")
      .is-flex.is-justify-content-flex-end
        b-button(type="is-ghost" @click="showNewPressLinkModal = false") Cancel
        b-button(type="is-info" :disabled="!canSavePressLink" @click="savePressLink") {{ isEdit ? 'Update' : 'Create' }}
  b-modal(v-if="loggedIn" v-model="showNewQuoteModal" @after-leave="resetQuote")
    .box
      h2.title {{ isEdit ? 'Update' : 'Create' }} Quote
      b-field(label="Title")
        b-input(v-model="newQuote.quote" placeholder="Guy Likes Wieners")
      b-field(label="Link")
        b-input(v-model="newQuote.publisher" placeholder="Hot Tamales Magazine")
      b-field(label="Visible")
        b-checkbox(v-model="newQuote.visible")
      .is-flex.is-justify-content-flex-end
        b-button(type="is-ghost" @click="showNewQuoteModal = false") Cancel
        b-button(type="is-info" :disabled="!canSaveQuote" @click="saveQuote") {{ isEdit ? 'Update' : 'Create' }}
</template>

<script>
import { mapGetters } from 'vuex';
import { pressCollection, quotesCollection } from '../firebase';

export default {
  name: 'Press',
  data() {
    return {
      isEdit: false,
      newPressLink: { date: new Date(), visible: true },
      newQuote: { visible: true },
      showNewPressLinkModal: false,
      showNewQuoteModal: false,
    };
  },
  computed: {
    ...mapGetters(['pressLinks', 'loggedIn', 'quotes']),
    canSavePressLink() {
      return !!this.newPressLink.link
        && !!this.newPressLink.title
        && !!this.newPressLink.date;
    },
    canSaveQuote() {
      return !!this.newQuote.quote
        && !!this.newQuote.publisher;
    },
  },
  methods: {
		formatDate(date) {
      return date.toLocaleDateString('en-US');
    },
    editPressLink(pressId) {
      this.isEdit = true;
      this.showNewPressLinkModal = true;
      this.newPressLink = this.pressLinks.find(press => pressId === press.id);
    },
    editQuote(quoteId) {
      this.isEdit = true;
      this.showNewQuoteModal = true;
      this.newQuote = this.quotes.find(quote => quoteId === quote.id);
    },
    resetPressLink() {
      this.newPressLink = {
        date: new Date(),
        visible: true,
      };
    },
    resetQuote() {
      this.newQuote = {
        visible: true,
      };
    },
    async savePressLink() {
      try {
        await this.newPressLink.id ? pressCollection.doc(this.newPressLink.id).update(this.newPressLink) : pressCollection.add(this.newPressLink);
        this.$buefy.toast.open({ message: 'Successfully Saved Press Link', type: 'is-success' });
        this.showNewPressLinkModal = false;
      } catch {
        this.showNewPressLinkModal = false;
        this.$buefy.toast.open({ message: 'Failed to Save Press Link', type: 'is-danger' });
      }
    },
    async saveQuote() {
      try {
        await this.newQuote.id ? quotesCollection.doc(this.newQuote.id).update(this.newQuote) : quotesCollection.add(this.newQuote);
        this.$buefy.toast.open({ message: 'Successfully Saved Quote', type: 'is-success' });
        this.showNewQuoteModal = false;
      } catch {
        this.showNewQuoteModal = false;
        this.$buefy.toast.open({ message: 'Failed to Save Quote', type: 'is-danger' });
      }
    },
  },
};
</script>

<style lang="scss">
 .press-links {
    .secondary-text-color {
      color: #68b8c3;
    }

    @media screen and (max-width: 600px) {
      .quotes {
        flex-direction: column;
      }
    }
  }
</style>
