<template lang="pug">
  b-navbar.px-2.py-1.home-navbar(
    transparent
    centered
    :class="{'position-absolute': $route.path === '/'}"
    )
    template(slot="brand")
      b-navbar-item.logo2.pb-0(tag="router-link" :to="{ name: 'home' }")
        b-image(
          alt="Sqwerv green logo"
          :src="require('@/assets/logoGreen.png')"
          )
    template(slot="end")
      b-navbar-item(
        tag="router-link"
        :to="{ name: 'home' }"
        :class="{'green-color': $route.path !== '/'}"
        :active="$route.path === '/'"
        ) HOME
      b-navbar-item(
        tag="router-link"
        :to="{ name: 'shows' }"
        :class="{'green-color': $route.path !== '/'}"
        :active="$route.path === '/shows'"
        ) SHOWS
      b-navbar-item(
        tag="router-link"
        :to="{ name: 'about' }"
        :class="{'green-color': $route.path !== '/'}"
        :active="$route.path === '/about'"
        ) ABOUT
      b-navbar-item(
        tag="router-link"
        :to="{ name: 'videos' }"
        :class="{'green-color': $route.path !== '/'}"
        :active="$route.path === '/videos'"
        ) VIDEOS
      b-navbar-item(
        tag="router-link"
        :to="{ name: 'press' }"
        :class="{'green-color': $route.path !== '/'}"
        :active="$route.path === '/press'"
        ) PRESS
      b-navbar-item(
        tag="router-link"
        :to="{ name: 'contactUs' }"
        :class="{'green-color': $route.path !== '/'}"
        :active="$route.path === '/contactUs'"
        ) CONTACT US
      b-navbar-item(
        tag="router-link"
        :to="{ name: 'merch' }"
        :class="{'green-color': $route.path !== '/'}"
        :active="$route.path === '/merch'"
        ) MERCH
</template>

<script>

import SocialNavbar from '@/components/SocialNavbar.vue';

export default {
  name: 'Navbar',
  components: {
    SocialNavbar,
  },
};
</script>

<style lang="scss">
  .home-navbar {
    height: 10vh;
    background: transparent !important;
    width: 100%;
    .green-color {
      color: #68b8c3;
    }
    .navbar-end {
      .navbar-item {
        font-weight: 600;
        padding-left: 1.3em;
        font-size: 1.2em;
        &:hover {
          color: gold;
          text-decoration: underline !important;
          text-decoration-color: gold !important;
          text-decoration-thickness: 3px !important;
        }
      }
      .is-active {
        color: gold !important;
        text-decoration: underline !important;
        text-decoration-color: gold !important;
        text-decoration-thickness: 3px !important;
      }
    }
  }
  .position-absolute {
    position: absolute !important;
  }
  .logo2 {
    img{
      padding-top: 30px;
      width: 185px !important;
      max-height: 120px !important;
    }
  }

  @media screen and (max-width: 1023px) {
    .logo2 {
      img{
        padding-top: 0;
        width: 130px !important;
        max-height: 100px !important;
      }
    }
    .home-navbar {
      .navbar-menu {
        a.navbar-item {
          color: #68b8c3;
        }
        a.navbar-item:hover {
          background-color: inherit;
          text-decoration: underline !important;
          text-decoration-color: gold !important;
          text-decoration-thickness: 3px !important;
        }
      }
    }
  }
</style>
