<template lang="pug">
.news.py-4.px-3
  .container.is-max-desktop
    .columns.pb-4
      .column.has-text-centered
        h2.title.secondary-text-color.is-3 News
      .column.is-narrow
        b-button(v-if="loggedIn" type="is-info" @click="showNewArticleModal = true") Add Article
    .articles(v-for="article in news" :key="article.title")
      .article(v-if="article.visible")
        .columns
          .column
            .is-flex
              h4.title.is-4.has-text-grey.mr-3 {{ article.title }}
              b-button.mr-1.is-align-self-center(
                v-if="loggedIn" 
                icon-right="pencil-alt"
                size="is-small"
                type="is-warning"
                @click="editArticle(article.id)"
                )
            p.has-text-grey.is-size-5 {{ article.description }}
            .is-flex.mt-3.is-align-items-baseline(v-if="article.link")
              a.is-size-6.news-link.has-text-grey(:href="article.link" target="_blank") {{ article.linkText }}
              b-icon.ml-1.has-text-grey(icon="external-link-alt" size="is-small")
          .column.is-3
            b-image.object-contain(:src="article.imgPath" :ratio="article.imgRatio" :alt="article.imgAlt")        
        hr
  b-modal(v-if="loggedIn" v-model="showNewArticleModal" @after-leave="resetArticle")
    .box
      h2.title {{ isEdit ? 'Update' : 'Create' }} New Article
      b-field(label="Title")
        b-input(v-model="newArticle.title" placeholder="Guy Likes Wieners")
      b-field(label="Description")
        b-input(v-model="newArticle.description" type="textarea" placeholder="Guy REALLY REALLY REALLY likes wieners")
      b-field(label="Link")
        b-input(v-model="newArticle.link")
      b-field(label="Link Icon")
        b-input(v-model="newArticle.linkIcon" placeholder="Colorado")
      b-field(label="Link Text")
        b-input(v-model="newArticle.linkText")
      b-field(label="Image Link")
        b-input(v-model="newArticle.imgPath" placeholder="Maybe so or maybe not")
      b-field(label="Image Ratio")
        b-input(v-model="newArticle.imgRatio")
      b-field(label="Image Alt Text")
        b-input(v-model="newArticle.imgAlt" placeholder="Text describing image")
      b-field(label="Visible")
        b-checkbox(v-model="newArticle.visible")
      .is-flex.is-justify-content-flex-end
        b-button(type="is-ghost" @click="showNewArticleModal = false") Cancel
        b-button(type="is-info" :disabled="!canSaveArticle" @click="saveArticle") {{ isEdit ? 'Update' : 'Create' }}
</template>

<script>
import { mapGetters } from 'vuex';
import { newsCollection } from '../firebase';

export default {
  name: 'News',
  data() {
    return {
      isEdit: false,
      newArticle: {
        imgRatio: '5by3',
        linkIcon: 'external-link',
        linkText: 'More Info',
        visible: true,
      },
      showNewArticleModal: false,
    };
  },
  computed: {
    ...mapGetters(['news', 'loggedIn']),
    canSaveArticle() {
      return !!this.newArticle.description
        && !!this.newArticle.imgAlt
        && !!this.newArticle.imgPath
        && !!this.newArticle.imgRatio
        && !!this.newArticle.link
        && !!this.newArticle.linkIcon
        && !!this.newArticle.linkText
        && !!this.newArticle.title;
    },
  },
  methods: {
    editArticle(articleId) {
      this.isEdit = true;
      this.showNewArticleModal = true;
      this.newArticle = this.news.find(article => articleId === article.id);
    },
    resetArticle() {
      this.newArticle = {
        imgRatio: '5by3',
        linkIcon: 'external-link',
        linkText: 'More Info',
        visible: true,
      };
    },
    async saveArticle() {
      if (!this.newArticle.id) {
        this.newArticle.dateCreated = new Date();
      }
      try {
        await this.newArticle.id ? newsCollection.doc(this.newArticle.id).update(this.newArticle) : newsCollection.add(this.newArticle);
        this.$buefy.toast.open({ message: 'Successfully Saved Article', type: 'is-success' });
        this.showNewArticleModal = false;
      } catch {
        this.showNewArticleModal = false;
        this.$buefy.toast.open({ message: 'Failed to Save Article', type: 'is-danger' });
      }
    },
  },
};
</script>

<style lang="scss">
  .news {
    .secondary-text-color {
      color: #68b8c3;
    }
    .news-link {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    .object-contain {
      img {
        object-fit: contain;
      }
    }
  }
</style>
