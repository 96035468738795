<template lang="pug">
  .navbar-admin.pr-2
    b-navbar(type="is-black")
      template(slot="end")
        .is-flex.is-align-items-center
          p.mr-2 Hello, {{ username }}
          b-button(type="is-white" size="is-small" @click="logout") Logout
</template>

<script>
export default {
  name: 'Navbar',
  computed: {
    username() {
      return this.$store.state.username;
    },
  },
  methods: {
    logout() {
      return this.$store.dispatch('logout');
    },
  },
};
</script>

<style lang="scss">
  .navbar-admin {
    background: black;
  }
</style>
