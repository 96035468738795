
import Vue from 'vue';
import Vuex from 'vuex';
import * as fb from '../firebase';
import router from '../router';

Vue.use(Vuex)

fb.merchCollection.orderBy('dateCreated', 'desc').onSnapshot((snapshot) => {
  let merchArray = [];
  snapshot.forEach((doc) => {
    let merchItem = doc.data();
    merchItem.id = doc.id;
    merchArray.push(merchItem);
  })

  store.commit('SET_MERCH', merchArray);
})
fb.showsCollection.orderBy('date', 'asc').onSnapshot((snapshot) => {
  let showsArray = [];
  snapshot.forEach((doc) => {
    let show = doc.data();
    show.id = doc.id;
    show.location = `${show.city}, ${show.state}`;
    show.date = new Date(show.formattedDate);
    showsArray.push(show);
  })

  store.commit('SET_SHOWS', showsArray);
})
fb.newsCollection.orderBy('dateCreated', 'desc').onSnapshot((snapshot) => {
  let newsArray = [];
  snapshot.forEach((doc) => {
    let article = {};
    const {
      dateCreated,
      description,
      imgAlt,
      imgPath,
      imgRatio,
      link,
      linkIcon,
      linkText,
      title,
      visible,
    } = doc.data();
    article = {
      dateCreated,
      description,
      id: doc.id,
      imgAlt,
      imgPath,
      imgRatio,
      link,
      linkIcon,
      linkText,
      title,
      visible,
    }
    newsArray.push(article);
  })

  store.commit('SET_NEWS', newsArray);
})
fb.pressCollection.orderBy('date', 'desc').onSnapshot((snapshot) => {
  let pressArray = [];
  snapshot.forEach((doc) => {
    let pressItem = doc.data();
    pressItem.id = doc.id;
    pressItem.date = new Date(pressItem.date.seconds*1000);
    pressArray.push(pressItem);
  })

  store.commit('SET_PRESS', pressArray);
})
fb.quotesCollection.onSnapshot((snapshot) => {
  let quoteArray = [];
  snapshot.forEach((doc) => {
    let quoteItem = doc.data();
    quoteItem.id = doc.id;
    quoteArray.push(quoteItem);
  })

  store.commit('SET_QUOTES', quoteArray);
})
fb.videosCollection.orderBy('dateCreated', 'desc').onSnapshot((snapshot) => {
  let videoArray = [];
  snapshot.forEach((doc) => {
    let videoItem = doc.data();
    videoItem.id = doc.id;
    videoArray.push(videoItem);
  })

  store.commit('SET_VIDEOS', videoArray);
})

const store = new Vuex.Store({
  state: {
    merch: [],
    news: [],
    pressLinks: [],
    quotes: [],
    videos: [],
    loginFailed: false,
    shows: [],
    userLoggedIn: false,
    username: '',
  },
  mutations: {
    LOGIN_FAILED(state) {
      state.loginFailed = true;
    },
    LOGIN_USER(state) {
      state.userLoggedIn = true;
      state.username = 'SqwervAdmin';
      state.loginFailed = false;
    },
    LOGOUT_USER(state) {
      state.userLoggedIn = false;
      state.username = '';
    },
    SET_MERCH(state, val) {
      state.merch = val;
    },
    SET_NEWS(state, val) {
      state.news = val;
    },
    SET_SHOWS(state, val) {
      state.shows = val;
    },
    SET_PRESS(state, val) {
      state.pressLinks = val;
    },
    SET_QUOTES(state, val) {
      state.quotes = val;
    },
    SET_VIDEOS(state, val) {
      state.videos = val;
    },
  },
  actions: {
    async login({ commit }, form) {
      try {
        await fb.auth.signInWithEmailAndPassword(form.email, form.password);
        commit('LOGIN_USER');
        router.push('/');
      } catch {
        commit('LOGIN_FAILED');
      }
    },
    async logout({ commit }) {
      await fb.auth.signOut();
      commit('LOGOUT_USER');
      router.push('/');
    },
  },
  getters: {
    loginFailed: state => state.loginFailed,
    loggedIn: state => state.userLoggedIn,
    merch: state => state.merch,
    news: state => state.news,
    shows: state => state.shows,
    pressLinks: state => state.pressLinks,
    quotes: state => state.quotes,
    videos: state => state.videos,
  },
});

export default store;
