<template lang="pug">
  .contact-us.container.px-3
    h2.title.secondary-text-color Contact Us
    p.has-text-grey.is-size-6.mb-3 For all booking inquiries, please reach out to Justin@looseleaftalentagency.com.
    b-field.required(label="Name")
      b-input(v-model="email.name" placeholder="Your Name...")
    b-field.required(label="Your Email")
      b-input(v-model="email.email" placeholder="Your Email...")
    b-field.required(label="Subject")
      b-input(v-model="email.subject" placeholder="Subject...")
    b-field.required(label="Message")
      b-input(v-model="email.message" placeholder="Message..." type="textarea")
    .has-text-right
      b-button(type="is-primary" @click="sendEmail" :disabled="!canSendEmail") Send Email
    b-loading(v-model="isLoading" is-full-page)
</template>

<script>
import { every } from 'lodash';
import emailjs from 'emailjs-com';

const SERVICE_ID = 'service_dmaylf5';
const TEMPLATE_ID = 'template_2b3mi7k';
const USER_ID = 'user_6cTcGqYYyin2K27Pv3YnB';

export default {
  metaInfo() {
    return {
      title: 'Contact Us Page - Sqwerv',
      meta: [
        {
          hid: 'sqwervContactUsPage',
          name: 'description',
          content: 'Sqwerv contact us page. Where users can email us directly for gigs, promotion, radio, album info',
        },
      ],
    }
  },
  name: 'ContactUs',
  data() {
    return {
      email: {},
      isLoading: false,
    };
  },
  computed: {
    canSendEmail() {
      return every(['name', 'email', 'subject', 'message'], key => this.email[key]);
    },
  },
  methods: {
    sendEmail() {
      this.isLoading = true;
      emailjs.send(SERVICE_ID, TEMPLATE_ID, this.email,  USER_ID)
        .then(() => {
          this.success();
          this.email = {};
        })
        .catch(() => this.failed())
        .finally(() => {
          this.isLoading = false;
        });
    },
    success() {
      this.$buefy.toast.open({
        message: 'Email Sent!',
        type: 'is-success'
      })
    },
    failed() {
      this.$buefy.toast.open({
        message: 'Email Failed to send',
        type: 'is-danger'
      })
    },
  },
};
</script>

<style lang="scss" scoped>
  .contact-us {
    padding-top: 3% !important;
    padding-bottom: 12em;
    .secondary-text-color {
      color: #68b8c3;
    }
  }
</style>
